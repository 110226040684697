// Imports
import SplitType from 'split-type';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

/**
 * RevealText Module
 *
 * Animates text using GSAP's ScrollTrigger and SplitType.
 */
class ImpactRevealText {
    constructor() {
        this.attr = 'data-reveal-text-impact';
    }

    init() {
        this.components = document.querySelectorAll(`[${this.attr}]`);

        this.components.forEach((component) => {
            // Default settings
            let defaultOptions = {
                type: 'chars',
                opacity: 0.1,
                color: '#000',
                stagger: 0.3,
                start: 'top 80%',
                end: 'bottom 20%',
                markers: true, // Enable markers for debugging
            };

            // Parse JSON options from the attribute and merge with defaults
            let dataOptions = this._parseOptions(component.getAttribute(this.attr));
            let options = { ...defaultOptions, ...dataOptions };

            // Apply SplitType
            const text = new SplitType(component, { types: ['chars', 'words'] });

            // Ensure correct text selection
            const elements = text[options.type];

            if (!elements || elements.length === 0) {
                console.error(`ImpactRevealText: No elements found for type "${options.type}"`, component);
                return;
            }

            // GSAP Animation (Fixes opacity issue)
            gsap.fromTo(
                elements,
                { opacity: options.opacity }, // Start from low opacity
                {
                    opacity: 1, // Fully visible
                    stagger: options.stagger,
                    scrollTrigger: {
                        trigger: component,
                        start: options.start,
                        end: options.end,
                        scrub: true
                    },
                }
            );
        });
    }

    _parseOptions(data) {
        try {
            return data ? JSON.parse(data) : {};
        } catch (e) {
            console.error('Invalid JSON in data-reveal-text attribute:', e);
            return {};
        }
    }
}

// Export the module
export default new ImpactRevealText();
